<template>
  <v-container>
    <v-card>
      <v-data-table :headers="headers" :items="sellers" class="elevation-1">
        <template v-slot:top>
          <v-toolbar dense flat class="teal" dark>
            <v-toolbar-title>Vendedores </v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
        </template>

        <template v-slot:item.time="{ item }">
          <!--eslint-disable-line -->

          <v-chip label color="primary" outlined>
            {{ item.time }}
          </v-chip>
        </template>

        <template v-slot:item.status="{ item }">
          <!-- eslint-disable-line-->

          <!--eslint-disable-line -->
          <v-switch v-model="item.deleted"></v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <!-- eslint-disable-line-->

          <!--eslint-disable-line -->

          <!-- eslint-disable-line-->

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="sellerSelected(item)"
              >
                <v-icon>mdi-account-convert</v-icon>
              </v-btn>
            </template>
            <span>Alterar time..</span>
          </v-tooltip>
           <v-btn
            text
            icon
            color="red lighten-2"
            @click="(dialogRemoveSeller = true), loadSeller(item)"
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-divider class="mt-10"></v-divider>

    <v-dialog
      v-model="dialog"
      v-if="dialog"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="teal" dark>
          <v-toolbar-title>Alteração de time</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"
            ><v-icon arge>mdi-close-circle</v-icon></v-btn
            >
        </v-toolbar>
        <v-card-text>
          <v-alert dense outlined type="error" class="mt-2">
            Atenção! Ao confirmar a alteração, o vendedor iniciará um
            <strong>novo ciclo de vendas</strong>!
          </v-alert>
          <v-select
            outlined
            :items="teams"
            item-text="nome"
            single-line
            return-object
            :label="teamSelected.time"
            v-model="teamSelected"
            @change="disabled = false"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="disabled"
            @click="changeTime"
            color="primary"
            block
            depressed
            >Confirmar alteração</v-btn
          >

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      class="justify-center"
      v-model="dialogRemoveSeller"
      overlay-opacity="0.3"
      max-width="400px"
      transition="dialog-transition"
    >
      <removeSeller
        :vendedorSelected="seller"
        v-on:close-dialog="closeRemoveSeller"
        v-on:update-list="refreshList"
        v-if="dialogRemoveSeller"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import removeSeller from "./removeSeller";
export default {
  components: {
    removeSeller
  },
  data: () => ({
    valid: true,
    dialog: false,
    disabled: true,
    teams: null,
    seller: null,
    teamSelected: null,
    name: "",
    email: "",
    select: null,
    items: ["Externo", "Interno", "Loja"],
    headers: [
      { text: "Vendedor", value: "nome" },
      { text: "Time", value: "time" },
      { text: "Ações", value: "actions" },
      { text: "Status", value: "status" }
    ],
    dialogRemoveSeller: false,

    checkbox: false,
    show2: false,
    sellers: [],
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Minimo de 8 caracteres"
    }
  }),
  created() {
    this.getAllSeller();
    this.getTeams();
  },
  methods: {
    sellerSelected(args) {
      this.dialog = true;
      this.seller = args.id;
      this.teamSelected = args;
    },
    getColor(status) {
      if (!status) return "success";
      else return "error";
    },
    async getAllSeller() {
      const result = await this.$http.get("/saleperson/teams");
      this.sellers = result.data;
    },
    async changeTime() {
      try {
        await this.$http.put(`/saleperson/${this.seller}`, {
          fk_time: this.teamSelected.id
        });
        this.getAllSeller();
        this.dialog = false;
        this.$toast.success("Time alterado com sucesso!");
      } catch (error) {
        this.$toast.error("Erro ao alterar time!");
      }
    },
    async getTeams() {
      const result = await this.$http.get("/team");
      this.teams = result.data;
    },
    submit() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        // axios.post("/api/submit", {
        //     name: this.name,
        //     email: this.email,
        //     select: this.select,
        //     checkbox: this.checkbox
        // });
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    loadSeller(seller) {
      console.log(seller)
      this.seller = seller;
    },
    refreshList() {
     
      this.dialogRemoveSeller = false;
      this.getAllSeller();
    },
    closeRemoveSeller() {
      this.dialogRemoveSeller = false;
    }
  }
};
</script>

<style>
</style>