<template>
  <v-card flat>
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Remover Vendedor</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title> Você deseja remover este vendedor?</v-card-title>
    <v-card-text>
      <v-alert dense outlined type="error" class="mt-2">
        Atenção! Ao confirmar a alteração,
        <strong> o vendedor será removido</strong>!
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text v-on:click="$emit('close-dialog')">
        <v-icon arge>mdi-close</v-icon> Cancelar</v-btn
      >
      <v-btn color="success" text @click="removeSeller()">
        <v-icon arge>mdi-check</v-icon> Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["vendedorSelected"],
  created() {
    this.setDataSeller();
  },
  data: () => {
    return {
      vendedor: {
        id: null,
        name: "",
      }
    };
  },
  methods: {
    setDataSeller() {
      console.log(this.vendedorSelected)
      this.vendedor.id = this.vendedorSelected.id;
      this.vendedor.name = this.vendedorSelected.nome;
    },
    async removeSeller() {
      const id = this.vendedor.id;
      const result = await this.$http.delete(`/saleperson/${id}`);
      if (result.status == 200) {
        this.$toast.success("Vendedor Removido com Sucesso!");
        this.$emit("update-list");
      } else {
        this.$toast.error("Falha ao Remover Vendedor!");
      }
    }
  }
};
</script>

<style>
</style>